var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-design-page d-flex flex-column fill-height"},[(_vm.loading)?_c('v-flex',{staticClass:"d-flex justify-center align-center py-16"},[_c('v-progress-circular',{attrs:{"size":100,"color":"accent","indeterminate":""}})],1):[_c('PageHeader',[_c('div',{staticClass:"d-flex align-center justify-space-between mb-8 overflow-hidden space-x-4"},[_c('h1',{staticClass:"d-flex align-center text-title-1 overflow-hidden"},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","color":"gray-60","exact":""},on:{"click":_vm.goBack}},[_c('IconArrowLeft',{staticStyle:{"margin-top":"2px"},attrs:{"width":"16"}})],1),_c('v-tooltip',{attrs:{"top":"","color":"rgba(47, 49, 53, 0.7)"},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('div',{staticClass:"d-flex overflow-hidden align-center"},[_c('span',_vm._g(_vm._b({staticClass:"text-truncate"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.getProject.title)+" ")]),_c('ProjectDesignListPageMenu',{attrs:{"menuItems":_vm.menuItems,"showBtn":true}})],1)]}}])},[_c('div',{staticClass:"text-captions-1"},[_vm._v(" "+_vm._s(_vm.getProject.title)+" ")])])],1),_c('div',{staticClass:"flex-shrink-0"},[_c('UiBtn',{attrs:{"outlined":"","link":"","color":"accent","to":{
              name: 'ProjectEdit',
              params: { project_id: this.$route.params.project_id },
            }}},[_c('IconSlidersV',{staticClass:"mr-2",attrs:{"width":"16"}}),_vm._v(" Specification ")],1),(_vm.projectMoodBoardModule)?_c('UiBtn',{staticClass:"ml-3",attrs:{"outlined":"","link":"","color":"accent","to":{
              name: 'MoodBoard',
              params: { project_id: this.$route.params.project_id },
            }}},[_c('IconMoodboard',{staticClass:"mr-2",attrs:{"width":"16"}}),_vm._v(" Whiteboard ")],1):_vm._e(),_c('UiBtn',{staticClass:"ml-3 d-lg-none",attrs:{"color":"accent","fab":"","disabled":_vm.getProject.id === null},on:{"click":function($event){return _vm.$store.dispatch(
                'toggleProjectSideDrawer',
                !_vm.showProjectSideDrawer
              )}}},[(_vm.showProjectSideDrawer)?_c('IconExpandRight',{attrs:{"width":"16"}}):_c('IconExpandLeft',{attrs:{"width":"16"}})],1)],1)]),_c('div',{staticClass:"d-flex align-center space-x-3 mb-1"},[_c('ModulesNavigation',{staticClass:"flex",attrs:{"modules":_vm.getDesignModules},model:{value:(_vm.activeModuleId),callback:function ($$v) {_vm.activeModuleId=$$v},expression:"activeModuleId"}}),_c('ModulesMenu',{on:{"createDesign":_vm.createDesign}})],1),_c('SideDrawerControls',{staticClass:"d-none d-lg-flex"})],1),_c('v-container',{staticClass:"relative"},[_c('div',{staticClass:"d-flex align-center justify-space-between mt-2 mb-5"},[_c('Tags',{attrs:{"tags":_vm.tags},on:{"addNewTag":(tag) => _vm.tags.unshift(tag),"setFilter":_vm.setFilter}}),(
            _vm.$vuetify.breakpoint.smAndUp &&
            _vm.getProject.status !== _vm.$config.project.status.approved
          )?_c('SingleItemDueDate',{on:{"updateData":_vm.updateProjectDueDate}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-center justify-space-between mb-5"},[_c('div',{staticClass:"d-flex align-center"},[_c('UiGridViewType',{staticClass:"mr-6",attrs:{"list":_vm.$config.project.gridView.thumbnail,"active":_vm.getGridView.design.gridView.type === 'thumbnail' &&
              _vm.getGridView.design.gridView},on:{"setGridView":function($event){return _vm.setGridView({
                key: 'design',
                value: { gridView: $event, isGrid: true },
              })}}}),_c('UiGridViewType',{staticClass:"mr-6",attrs:{"list":_vm.$config.project.gridView.list,"active":_vm.getGridView.design.gridView.type === 'list' &&
              _vm.getGridView.design.gridView},on:{"setGridView":function($event){return _vm.setGridView({
                key: 'design',
                value: { gridView: $event, isGrid: false },
              })}}})],1),(!_vm.noCreatedStyles)?_c('div',{staticClass:"d-flex align-center"},[_c('UiFilter',{attrs:{"filterData":_vm.filterData,"offset-y":"","allow-overflow":"","left":"","openFilterBtnClasses":"mr-6"},on:{"setFilters":_vm.setFilter}}),_c('UiSort',{staticClass:"mr-6",attrs:{"mandatory1":"","mandatory2":"","list":_vm.sortList1,"list2":_vm.sortList2,"value1":_vm.sort_1,"value2":_vm.sort_2},on:{"setSort1":(value) => this.setSort('sort_1', value),"setSort2":(value) => this.setSort('sort_2', value)}}),_c('UiSearch',{staticClass:"design-search",on:{"onSearch":_vm.searchDesign}})],1):_vm._e()]),(
          !_vm.designsLoading &&
          _vm.getProjectDesigns.length &&
          _vm.getGridView.design.isGrid
        )?_c('GridView',{staticClass:"mb-3",attrs:{"size":_vm.getGridView.design.gridView.slug},on:{"downloadFile":_vm.downloadFile,"updateAllData":_vm.updateAllData,"updateDesignsData":_vm.getDesignsData}}):_vm._e(),(
          !_vm.designsLoading &&
          _vm.getProjectDesigns.length &&
          !_vm.getGridView.design.isGrid
        )?_c('ListView',{attrs:{"size":_vm.getGridView.design.gridView.slug},on:{"downloadFile":_vm.downloadFile,"updateAllData":_vm.updateAllData,"updateDesignsData":_vm.getDesignsData}}):_vm._e(),(!_vm.designsLoading)?_c('div',{staticClass:"relative"},[_c('UploadDesign',{attrs:{"simple-view":!_vm.noCreatedStyles,"abilityToUpload":_vm.abilityToUpload,"hideText":!_vm.getProject?.scheme?.settings[_vm.activeModuleWorkflowOpenTypeSlug] &&
            !_vm.abilityToUpload &&
            (!_vm.getActiveDesignModule?.styles?.length ||
              !_vm.getProjectDesigns?.length)},on:{"createDesign":_vm.createDesign}}),(
            !_vm.getProject?.scheme?.settings[_vm.activeModuleWorkflowOpenTypeSlug] &&
            !_vm.abilityToUpload &&
            (!_vm.getActiveDesignModule?.styles?.length ||
              !_vm.getProjectDesigns?.length)
          )?_c('UnavailableModuleInfo',{staticClass:"unavailable-module",attrs:{"styleListView":""},on:{"updateModuleId":function($event){_vm.activeModuleId = $event}}}):_vm._e()],1):_vm._e(),(_vm.designsLoading)?_c('v-flex',{staticClass:"d-flex justify-center align-center py-16"},[_c('v-progress-circular',{attrs:{"size":100,"color":"accent","indeterminate":""}})],1):_vm._e(),(
          !_vm.getProjectDesigns.length && !_vm.designsLoading && !_vm.noCreatedStyles
        )?_c('v-flex',{staticClass:"d-flex justify-center align-center py-16 font-weight-semi-bold",staticStyle:{"font-size":"28px"}},[_vm._v(" Styles not found ")]):_vm._e()],1),(_vm.showDesignBulkActions)?_c('PageFooter',{staticClass:"px-0"},[_c('DesignItemsBulkActions',{on:{"updateDesignsData":_vm.getDesignsData}})],1):_vm._e(),(_vm.getModal('requestReviewModal').data.files.length)?_c('RequestReviewModal',{attrs:{"active-module-id":_vm.getActiveDesignModule.id},on:{"requestReview":_vm.requestReview}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="design-items-wrap">
    <UiListCard
      v-for="design in getProjectDesigns"
      :key="design.id"
      :item="design"
      :itemStatus="design.status || null"
      :selected="design.checked"
      :selectable="false"
      @onSelect="value => onSelect({id: design.id, checked: value})"
      :to="go(design)"
      :class="[`design-item mb-3 relative ${size}`, {
        'is-loading': design.loading
      }]"
    >
<!--      <template #badges>-->
<!--          <UiBadge color="primary" v-if="design.is_new">New</UiBadge>-->
<!--          <UiBadge color="in_review" v-if="design.review_needed">Review Needed</UiBadge>-->
<!--      </template>-->
      <template v-slot:image>
        <UiSelectedIndicator
          class="absolute mt-2 ml-2"
          :selected="design.checked"
          @click.prevent="onSelect({id: design.id, checked: !design.checked})"
        />
        <UIImage class="fill-width fill-height" v-if="!design.items.length || !design.items[0]" :name-path="`default-img.png`"/>
        <img v-else-if="$config.filesystem.fileTypes.image.includes(getFileExtension(design.items[0].file.original_name))" :src="getFileThumbnails(design.items[0].file.thumbnails) || design.items[0].file.url" alt="" class="fill-width fill-height object-cover">
        <UiFileIcon v-else width="30" class="ma-auto" :extension="getFileName(design.items[0].file.original_name).ext"/>
      </template>

      <template v-slot:body>
        <v-flex v-if="design.loading" @click.stop class="design-item-loading d-flex justify-center align-center absolute">
          <v-progress-circular
            :size="64"
            color="accent"
            indeterminate
          />
        </v-flex>

        <div class="design-item-section-wrap d-flex align-center">
          <div class="design-item-section px-5 mr-auto">
            <div class="d-flex align-center mb-1">
              <div class="text-overflow-ellipsis overflow-hidden">
                <v-tooltip top color="accent">
                  <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                    v-show="!design.showRenameInput"
                    @click.prevent="getPermission($route.params.project_id).design['can-rename-style-design'] ? design.showRenameInput = true : $event.preventDefault"
                    class="design-item-style-name text-body text-truncate font-weight-semi-bold gray-80--text"
                  >
                    {{design.title}}
                  </span>
                  </template>
                  <span class="white--text font-weight-medium">{{design.title}}</span>
                </v-tooltip>
                <UiChangeNameInput
                  v-if="getPermission($route.params.project_id).design['can-rename-style-design'] && design.showRenameInput"
                  v-model="design.newTitle"
                  placeholder="Enter design style"
                  :rules="'required|min:3|max:128'"
                  save-on-blur
                  @onSave="updateDesignStyle(design)"
                  @onClose="design.showRenameInput = false"
                />
              </div>

              <div class="flex-shrink-0 d-flex align-center">
                <IconDoubleChevronRight v-if="design.items.length && !design.showRenameInput" width="10" class="mx-3 gray-30--text"/>

                <v-tooltip v-if="design.items.length && !design.showRenameInput" top color="accent">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="text-captions-1 text-no-wrap overflow-hidden text-overflow-ellipsis gray-60--text">
                      V<strong class="font-weight-semi-bold gray-100--text">{{design.items[0].version}}</strong>
                    </div>
                  </template>
                  <span class="white--text font-weight-medium">V{{design.items[0].version}}</span>
                </v-tooltip>
              </div>

            </div>

            <div class="d-flex align-center overflow-hidden">
              <UiProjectModuleIcon width="12" :icon="getActiveDesignModule.icon" class="mr-1 flex-shrink-0 gray-60--text"/>
              <span class="text-captions-1 text-no-wrap text-overflow-ellipsis overflow-hidden">{{ getActiveDesignModule.title }}</span>
            </div>
          </div>

          <div class="design-item-section border-left px-5">
            <template v-if="design.items.length">
              <p class="mb-1 text-captions-1 gray-60--text">
                Updated: <strong class="font-weight-semi-bold">{{ timeDistance(design.items[0].updated_at) }}</strong>
              </p>
            </template>
            <SingleItemDueDate
              v-if="$vuetify.breakpoint.smAndUp && design.status !== $config.project.status.approved"
              type="module-style"
              :active-style="moduleStyle(design.id)"
              calendar-view-type="inline"
              :selected="getProjectDesigns?.filter(el => el.checked) || []"
              @updateData="updateDesignDueDate"
            >
              <template v-slot:activator="{attrs, on, dueDaysLeft }">
                <v-btn
                  class="due-date-btn"
                  :color="dueDaysLeft.backgroundColor"
                  v-bind="attrs"
                  v-on="on"
                  :elevation="0"
                  @click.prevent
                >
                  <span class="text-captions-2" :class="[dueDaysLeft.textColor]">{{ dueDaysLeft.prefix }}</span> <span class="dark text-captions-2 font-weight-bold ml-1" :class="[dueDaysLeft.textColor]">
                  {{ dueDaysLeft.value }} {{dueDaysLeft.suffix }}
                </span>
                </v-btn>
              </template>
            </SingleItemDueDate>
          </div>

          <div class="design-item-section border-left px-5">
            <v-tooltip v-if="design.items.length" top color="accent" :disabled="$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <UISelectAssignee
                    v-model="design.items[0].assignee"
                    :assignee-list="assigneeList"
                    :disabled="!getPermission($route.params.project_id).design['can-assignee-user-design']"
                    @input="updateAssignee(design)"
                  >
                    <template v-slot:input="{ assignedUser }">
                      <div v-if="assignedUser" class="d-flex align-center text-no-wrap text-captions-1" @click.prevent>
                        By:
                        <UiAvatar
                          :src="assignedUser.user.avatar ? assignedUser.user.avatar.url : ''"
                          :text="assignedUser.user.first_name"
                          size="22"
                          class="ml-2"
                        />
                        <span class="ml-1 overflow-hidden text-overflow-ellipsis">
                          {{ assignedUser.user.first_name }} {{ assignedUser.user.last_name }}
                        </span>
                      </div>
                    </template>
                  </UISelectAssignee>
                </div>
              </template>
              <span class="white--text font-weight-medium">{{ design.items[0].assignee.first_name }} {{ design.items[0].assignee.last_name }}</span>
            </v-tooltip>
          </div>

          <div class="design-item-section d-flex align-center border-left px-5">
            <UiBtn color="gray-60" icon class="ml-auto mr-4"
                   :disabled="!design.items.length"
                   :loading="design.fileDownloading"
                   @click.prevent="$emit('downloadFile', design)">
              <IconDownload width="14"/>
            </UiBtn>

            <UiBtn color="gray-60" icon disabled class="mr-4">
              <IconChat width="14"/>
            </UiBtn>

            <DesignItemActionsMenu :design="design" :disabled="!getProject?.scheme?.settings[activeModuleWorkflowOpenTypeSlug] && !design.ready_to_work" @updateDesignsData="$emit('updateDesignsData')"/>
          </div>
        </div>

      </template>
    </UiListCard>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {format, formatDistanceToNow} from "date-fns";
import UiListCard from "@/components/UI/UiListCard";
import UIImage from "@/components/UI/UIImage";
import UiAvatar from "@/components/UI/UiAvatar";
import UiBtn from "@/components/UI/UiBtn";
import UISelectAssignee from "@/components/UI/UISelectAssignee";
import UiSelectedIndicator from "@/components/UI/UiSelectedIndicator";
import UiChangeNameInput from "@/components/UI/UiChangeNameInput";
import UiProjectModuleIcon from "@/components/UI/UiProjectModuleIcon";
import DesignItemActionsMenu from "./DesignItemActionsMenu";
import UiFileIcon from "@/components/UI/UiFileIcon.vue";
import {getFileExtension, getFileName, getFileThumbnails} from "@/utils/helpers";
import SingleItemDueDate from "@/views/project/design-list/SingleItemDueDate.vue";
// import UiBadge from "@/components/UI/UiBadge.vue";

export default {
  name: 'ListView',
  components: {
    SingleItemDueDate,
    // UiBadge,
    UiFileIcon,
    IconDoubleChevronRight: () => import('@/components/icons/IconDoubleChevronRight'),
    IconDownload: () => import('@/components/icons/IconDownload'),
    IconChat: () => import('@/components/icons/IconChat'),

    UiListCard,
    UIImage,
    UiAvatar,
    UiBtn,
    UISelectAssignee,
    UiSelectedIndicator,
    UiChangeNameInput,
    UiProjectModuleIcon,
    DesignItemActionsMenu,
  },
  props: {
    size: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    ...mapGetters([
      'getProject',
      'getProjectDesigns',
      'getActiveDesignModule',
      'getProjectPermissionData',
      'getPermission',
    ]),
    activeModuleWorkflowOpenTypeSlug() {
      return this.$config.project.workflowOpenTypesById[this.getActiveDesignModule?.workflow_type]
    },
    assigneeList() {
      const assignees = []

      this.getProject.team.forEach(user => {
        user.user_role.forEach(role => {
          if(this.$config.project.userRoleByDesignModules[role].includes(this.getActiveDesignModule.slug)) {
            assignees.push(user)
          }
        })
      })

      return assignees
    },
    // assignedUser() {
    //   const {projectManager, projectOwner} = this.$config.project.userRole;
    //   let user = this.getProject.team.find(user => user.user_role.includes(projectManager));
    //   if (!user) {
    //     user = this.getProject.team.find(user => user.user_role.includes(projectOwner));
    //   }
    //
    //   return user;
    // },
  },
  methods: {
    format,
    getFileThumbnails,
    getFileExtension,
    getFileName,
    getPermissionByModule(permission) {
      const project_id = this.$route.params.project_id;
      return this.getPermission(project_id).design[permission] &&
        this.getProjectPermissionData(project_id).availableModules.includes(this.getActiveDesignModule.slug);
    },
    timeDistance(date) {
      const res = formatDistanceToNow(new Date(date), {addSuffix: true})
      return res.replace('about ', '')
    },
    go(design) {
      if (design.showRenameInput) return;
      return  {
        name: 'DesignViewPage',
        params: {
          project_id: this.$route.params.project_id,
        },
        query: {
          module_id: this.getActiveDesignModule.id,
          style_id: design.id,
          file_id: design.items.length ? design.items[0].id : '',
          gallery_group_id: design.items.length ? design.items[0].gallery_group_id : '',
        }
      };
    },
    async updateDesignStyle(design) {
      design.loading = true;

      try {
        const res = await this.$api.projectModuleDesign.updateDesignStyleName(
          this.$route.params.project_id, this.$store.getters.getActiveDesignModule.id, design.id, {title: design.newTitle}
        );
        design.title = res.data.title;
      } catch (error) {
        console.error(error);
      } finally {
        design.loading = false;
        design.showRenameInput = false;
      }
    },
    updateAssignee(design) {
      design.loading = true;
      this.$api.projectModuleDesign.updateAssignee(
        this.$route.params.project_id, this.getActiveDesignModule.id, design.id, design.items[0].id, {assignee_id: design.items[0].assignee.id}
      ).then(res => {
        design.items[0].assignee = {...design.items[0].assignee, ...res.data.assignee};
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        design.loading = false;
      })
    },
    onSelect(data) {
      const {id, checked} = data;
      const designs = this.getProjectDesigns.map(design => design.id === id ? {...design, checked} : design);
      this.$store.dispatch('setProjectDesigns', designs);
    },
    moduleStyle(styleId) {
      return this.getActiveDesignModule?.styles?.find(style => style.id === Number(styleId))
    },
    async updateDesignDueDate() {
      this.$emit('updateAllData')
    }
  }
}
</script>

<style scoped lang="scss">
.design-item {
  &.is-loading {
    pointer-events: none;
  }

  .design-item-loading {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--v-gray-0-base);
    z-index: 5;
  }

  .design-item-section-wrap {
    width: 100%;
    height: 100%;
  }

  .design-item-section {
    width: 25%;

    .v-btn.v-btn--disabled {
      opacity: .57;
    }
  }

  .border-left::before {
    content: '';
    position: absolute;
    top: 18px;
    bottom: 18px;
    margin-left: -20px;
    border-left: 1px solid var(--v-gray-20-base);
  }

  .design-item-style-name:hover {
    text-decoration: underline;
  }
}

.not-fit-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  img {
    max-width: 32px;
    max-height: 32px;
    object-fit: contain;
  }
}

.due-date-btn {
  max-height: 18px;
  padding: 4px !important;
  border-right: 3px !important;
}
</style>

<template>
  <div class="project-design-page d-flex flex-column fill-height">
    <v-flex v-if="loading" class="d-flex justify-center align-center py-16">
      <v-progress-circular :size="100" color="accent" indeterminate />
    </v-flex>

    <template v-else>
      <PageHeader>
        <div
          class="d-flex align-center justify-space-between mb-8 overflow-hidden space-x-4"
        >
          <h1 class="d-flex align-center text-title-1 overflow-hidden">
            <v-btn icon class="mr-1" @click="goBack" color="gray-60" exact>
              <IconArrowLeft width="16" style="margin-top: 2px" />
            </v-btn>
            <v-tooltip top color="rgba(47, 49, 53, 0.7)">
              <template #activator="{ attrs, on }">
                <div class="d-flex overflow-hidden align-center">
                  <span class="text-truncate" v-bind="attrs" v-on="on"
                    >{{ getProject.title }}
                  </span>
                  <ProjectDesignListPageMenu
                    :menuItems="menuItems"
                    :showBtn="true"
                  />
                </div>
              </template>
              <div class="text-captions-1">
                {{ getProject.title }}
              </div>
            </v-tooltip>
          </h1>
          <div class="flex-shrink-0">
            <UiBtn
              outlined
              link
              color="accent"
              :to="{
                name: 'ProjectEdit',
                params: { project_id: this.$route.params.project_id },
              }"
            >
              <IconSlidersV width="16" class="mr-2" />
              Specification
            </UiBtn>
            <UiBtn
              v-if="projectMoodBoardModule"
              outlined
              link
              color="accent"
              class="ml-3"
              :to="{
                name: 'MoodBoard',
                params: { project_id: this.$route.params.project_id },
              }"
            >
              <IconMoodboard width="16" class="mr-2" />
              Whiteboard
            </UiBtn>
            <UiBtn
              color="accent"
              fab
              :disabled="getProject.id === null"
              class="ml-3 d-lg-none"
              @click="
                $store.dispatch(
                  'toggleProjectSideDrawer',
                  !showProjectSideDrawer
                )
              "
            >
              <IconExpandRight v-if="showProjectSideDrawer" width="16" />
              <IconExpandLeft v-else width="16" />
            </UiBtn>
          </div>
        </div>
        <div class="d-flex align-center space-x-3 mb-1">
          <ModulesNavigation
            v-model="activeModuleId"
            :modules="getDesignModules"
            class="flex"
          />
          <ModulesMenu @createDesign="createDesign" />
        </div>
        <SideDrawerControls class="d-none d-lg-flex" />
      </PageHeader>

      <v-container class="relative">
        <div class="d-flex align-center justify-space-between mt-2 mb-5">
          <Tags
            :tags="tags"
            @addNewTag="(tag) => tags.unshift(tag)"
            @setFilter="setFilter"
          />
          <SingleItemDueDate
            v-if="
              $vuetify.breakpoint.smAndUp &&
              getProject.status !== $config.project.status.approved
            "
            @updateData="updateProjectDueDate"
          />
        </div>

        <div class="d-flex align-center justify-space-between mb-5">
          <div class="d-flex align-center">
            <UiGridViewType
              class="mr-6"
              :list="$config.project.gridView.thumbnail"
              :active="
                getGridView.design.gridView.type === 'thumbnail' &&
                getGridView.design.gridView
              "
              @setGridView="
                setGridView({
                  key: 'design',
                  value: { gridView: $event, isGrid: true },
                })
              "
            />

            <UiGridViewType
              class="mr-6"
              :list="$config.project.gridView.list"
              :active="
                getGridView.design.gridView.type === 'list' &&
                getGridView.design.gridView
              "
              @setGridView="
                setGridView({
                  key: 'design',
                  value: { gridView: $event, isGrid: false },
                })
              "
            />
          </div>

          <div v-if="!noCreatedStyles" class="d-flex align-center">
            <UiFilter
              :filterData="filterData"
              offset-y
              allow-overflow
              left
              @setFilters="setFilter"
              openFilterBtnClasses="mr-6"
            />

            <UiSort
              class="mr-6"
              mandatory1
              mandatory2
              :list="sortList1"
              :list2="sortList2"
              :value1="sort_1"
              :value2="sort_2"
              @setSort1="(value) => this.setSort('sort_1', value)"
              @setSort2="(value) => this.setSort('sort_2', value)"
            />

            <UiSearch class="design-search" @onSearch="searchDesign" />
          </div>
        </div>

        <GridView
          v-if="
            !designsLoading &&
            getProjectDesigns.length &&
            getGridView.design.isGrid
          "
          :size="getGridView.design.gridView.slug"
          class="mb-3"
          @downloadFile="downloadFile"
          @updateAllData="updateAllData"
          @updateDesignsData="getDesignsData"
        />

        <ListView
          v-if="
            !designsLoading &&
            getProjectDesigns.length &&
            !getGridView.design.isGrid
          "
          :size="getGridView.design.gridView.slug"
          @downloadFile="downloadFile"
          @updateAllData="updateAllData"
          @updateDesignsData="getDesignsData"
        />
        <div class="relative" v-if="!designsLoading">
          <UploadDesign
            :simple-view="!noCreatedStyles"
            :abilityToUpload="abilityToUpload"
            @createDesign="createDesign"
            :hideText="
              !getProject?.scheme?.settings[activeModuleWorkflowOpenTypeSlug] &&
              !abilityToUpload &&
              (!getActiveDesignModule?.styles?.length ||
                !getProjectDesigns?.length)
            "
          />
          <UnavailableModuleInfo
            v-if="
              !getProject?.scheme?.settings[activeModuleWorkflowOpenTypeSlug] &&
              !abilityToUpload &&
              (!getActiveDesignModule?.styles?.length ||
                !getProjectDesigns?.length)
            "
            class="unavailable-module"
            styleListView
            @updateModuleId="activeModuleId = $event"
          />
        </div>
        <v-flex
          v-if="designsLoading"
          class="d-flex justify-center align-center py-16"
        >
          <v-progress-circular :size="100" color="accent" indeterminate />
        </v-flex>

        <v-flex
          v-if="
            !getProjectDesigns.length && !designsLoading && !noCreatedStyles
          "
          class="d-flex justify-center align-center py-16 font-weight-semi-bold"
          style="font-size: 28px"
        >
          Styles not found
        </v-flex>
      </v-container>

      <PageFooter v-if="showDesignBulkActions" class="px-0">
        <DesignItemsBulkActions @updateDesignsData="getDesignsData" />
      </PageFooter>

      <RequestReviewModal
        v-if="getModal('requestReviewModal').data.files.length"
        :active-module-id="getActiveDesignModule.id"
        @requestReview="requestReview"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PageHeader from "@/components/layout/PageHeader";
import PageFooter from "@/components/layout/PageFooter";
import UiBtn from "@/components/UI/UiBtn";
import UiGridViewType from "@/components/UI/UiGridViewType";
import UiFilter from "@/components/UI/UiFilter";
import UiSearch from "@/components/UI/UiSearch";
import UiSort from "@/components/UI/UiSort";
import ModulesNavigation from "@/components/project/ModulesNavigation";
import ModulesMenu from "@/views/project/design-list/ModulesMenu";
import SingleItemDueDate from "@/views/project/design-list/SingleItemDueDate.vue";
import Tags from "@/views/project/design-list/Tags";
import UploadDesign from "@/views/project/design-list/UploadDesign";
import ListView from "@/views/project/design-list/ListView";
import GridView from "@/views/project/design-list/GridView";
import DesignItemsBulkActions from "@/views/project/design-list/DesignItemsBulkActions";
import RequestReviewModal from "@/components/modals/RequestReviewModal";
import { getFileExtension } from "@/utils/helpers";
import SideDrawerControls from "@/components/project/side-drawer/SideDrawerControls.vue";
import projectCompleteMixin from "@/mixins/projectCompleteMixin";
import ProjectDesignListPageMenu from "@/views/project/design-list/ProjectDesignListPageMenu";
import UnavailableModuleInfo from "@/views/project/design-view/UnavailableModuleInfo.vue";

export default {
  name: "ProjectDesignListPage",
  components: {
    UnavailableModuleInfo,
    SideDrawerControls,
    IconArrowLeft: () => import("@/components/icons/IconArrowLeft"),
    IconSlidersV: () => import("@/components/icons/IconSlidersV"),
    IconMoodboard: () => import("@/components/icons/IconMoodboard"),
    IconExpandRight: () => import("@/components/icons/IconExpandRight"),
    IconExpandLeft: () => import("@/components/icons/IconExpandLeft"),
    PageHeader,
    PageFooter,
    UiBtn,
    UiGridViewType,
    UiFilter,
    UiSearch,
    UiSort,
    ModulesNavigation,
    ModulesMenu,
    SingleItemDueDate,
    Tags,
    UploadDesign,
    ListView,
    GridView,
    DesignItemsBulkActions,
    RequestReviewModal,
    ProjectDesignListPageMenu,
  },
  mixins: [projectCompleteMixin],
  data() {
    const sortList1 = [
      { name: "Name", slug: "title" },
      { name: "Modified", slug: "updated_at" },
    ];
    return {
      loading: true,
      isGrid: false,
      gridView: this.$config.project.gridView.list[0],
      tags: [],
      sortList1,
      sortList2: [
        { name: "Ascending", slug: "asc" },
        { name: "Descending", slug: "desc" },
      ],
      filterData: [],
      sort_1: sortList1[0],
      sort_2: { name: "Ascending", slug: "asc" },
    };
  },
  computed: {
    ...mapGetters([
      "noCreatedStyles",
      "designsLoading",
      "getProjectDesigns",
      "getActiveDesignModule",
      "getDesignModules",
      "getModal",
      "getProject",
      "showProjectSideDrawer",
      "getProjectDesignsFilters",
      "getAllProjectStyles",
      "getProjectSideDrawerTasks",
      "getActiveSpace",
      "getGridView",
      "getPermission",
      "getActiveFolder",
      "getProjectPermissionData",
    ]),
    projectMoodBoardModule() {
      return this.getProject.modules.find(
        (module) => module.type === this.$config.project.moduleTypes.moodBoard
      );
    },
    activeModuleId: {
      get() {
        return this.$route.query.module ? +this.$route.query.module : null;
      },
      set(val) {
        this.changeActiveDesignModule(val);
      },
    },
    showDesignBulkActions() {
      return this.getProjectDesigns.some((design) => design.checked);
    },
    menuItems() {
      return [
        {
          title: "Mark project as completed",
          icon: "IconCheck",
          action: this.onCompleteProject,
          disabled: !this.getPermission(this.getProject.id)["project-s-drawer"][
            "complete-project"
          ],
        },
        {
          title: "Edit Flow",
          icon: "IconDraw",
          action: this.goToEditFlow,
          disabled: !this.getProject.can_edit_workflow_scheme,
        },
      ];
    },
    activeModuleWorkflowOpenTypeSlug() {
      return this.$config.project.workflowOpenTypesById[
        this.getActiveDesignModule?.workflow_type
      ];
    },
    abilityToUpload() {
      const modules = this.getProject.modules.filter(
        (module) =>
          module.workflow_type === this.getActiveDesignModule.workflow_type
      );
      modules.sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
      const currentModuleIdx = modules.findIndex(
        (module) => module.id === this.getActiveDesignModule.id
      );
      if (
        !this.getProject?.scheme?.settings[
          this.activeModuleWorkflowOpenTypeSlug
        ] &&
        currentModuleIdx
      ) {
        return false;
      }
      const project_id = this.$route.params.project_id;
      return (
        this.getPermission(project_id).design["can-create-style-design"] &&
        this.getProjectPermissionData(project_id).availableModules.includes(
          this.getActiveDesignModule.slug
        )
      );
    },
  },
  watch: {
    "$route.query.openReview": {
      handler() {
        this.checkReviewOpportunity();
      },
    },
    "$route.query.modal_task_id": {
      handler() {
        this.openTaskModal();
      },
    },
    getProjectDesigns: {
      handler(val) {
        this.$store.state.ProjectDesign.noCreatedStyles = val.length === 0;
      },
      deep: true,
    },
  },
  async created() {
    await this.setupProject();
    if (!this.getPermission(this.getProject.id)) {
      await this.getPermissions();
    }

    this.loading = false;

    this.checkReviewOpportunity();
    this.openTaskModal();
  },
  beforeDestroy() {
    this.$store.state.ProjectDesign.noCreatedStyles = false;
  },
  methods: {
    ...mapActions(["getProjectsTagsList", "setGridView"]),
    goBack() {
      if (this.getActiveFolder?.id) {
        this.$router.push({
          name: "Folder",
          params: { id: this.getActiveFolder.id },
        });
      } else if (this.getActiveSpace?.id) {
        this.$router.push({
          name: "Space",
          params: { id: this.getActiveSpace?.id },
        });
      } else {
        this.$router.push({ name: "Dashboard" });
      }
    },
    checkReviewOpportunity() {
      if (
        this.$route.query.openReview !== "" &&
        this.$route.query.openReview !== undefined
      ) {
        this.$store.dispatch("openModal", {
          modalName: "approveRejectModal",
        });
      }
    },
    initFiltersData() {
      const statuses = Object.keys(this.$config.project.statusLabels).reduce(
        (acc, status) => {
          if (+status !== this.$config.project.status.draft) {
            acc.push({
              id: +status,
              title: this.$config.project.statusLabels[status],
            });
          }
          return acc;
        },
        []
      );

      this.filterData.push({
        type: "status",
        title: "Status",
        list: statuses,
        isMultiple: true,
        activeFilters: this.getProjectDesignsFilters.statuses,
      });
    },
    async createDesign(files) {
      const { module, tags } = this.getProjectDesignsFilters;
      if (!tags.length) {
        this.$toast.open({
          message: "Select a tag to create a style!",
          type: "error",
          position: "top-right",
        });
        return;
      }
      await this.$store.dispatch("setProjectDesignsLoading", true);

      let formData = new FormData();

      if (
        this.getActiveDesignModule.slug ===
        this.$config.project.modules.cadDesign
      ) {
        const cadAllowedType = [
          ...this.$config.project.modulesFilesFormatGroup["cad-design"][1],
        ];
        const cadFiles = [];

        files.forEach((file) => {
          if (cadAllowedType.includes(getFileExtension(file.name))) {
            cadFiles.push(file);
          }
        });

        // if (cadFiles.length > 1) {
        //   await this.$store.dispatch('openModal', {
        //     modalName: 'fileUploadErrorModal',
        //     data: {
        //       type: 'limitError',
        //     }
        //   });
        //   return
        // }
      }

      files.forEach((file, idx) => {
        formData.append(`files[${idx}][file]`, file);

        tags.forEach((tag) => {
          formData.append(`files[${idx}][tags][]`, tag);
        });
      });

      try {
        const res = await this.$api.projectModuleDesign.create(
          this.$route.params.project_id,
          module,
          formData
        );

        const allStyles = this.getAllProjectStyles.length
          ? [...res.data, ...this.getAllProjectStyles]
          : [...res.data];

        this.$store.dispatch("setAllProjectStyles", allStyles);
        this.$store.state.ProjectDesign.noCreatedStyles = false;
        this.loading = false;

        if (res.data.length > 1) {
          this.$store.dispatch("setProjectDesigns", [
            ...res.data,
            ...this.getProjectDesigns,
          ]);
        } else {
          if (this.$route.name === "ProjectDesign") {
            this.$router.push({
              name: "DesignViewPage",
              params: {
                project_id: this.$route.params.project_id,
              },
              query: {
                module_id: this.getActiveDesignModule.id,
                style_id: res.data[0].id,
                file_id: res.data[0].items[0].id,
              },
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("setProjectDesignsLoading", false);

        // we need to load the project to check if the module has files
        // if module don't have any files, it will be available to remove
        if (this.$route.name === "ProjectDesign") {
          const project = await this.$api.project.get(this.getProject.id);
          this.$store.dispatch("setProject", project.data);
          this.$store.dispatch("setDesignModules", project.data.modules);
        }
      }
    },
    async downloadFile(design) {
      if (design.fileDownloading) return;
      design.fileDownloading = true;
      try {
        const files = await this.$api.projectModuleDesign.downloadStyleFiles(
          this.$route.params.project_id,
          design.id,
          {
            statuses: [this.$config.project.status.approved],
            latest_version: true,
          }
        );

        const fileURL = URL.createObjectURL(new Blob([files]));

        const anchor = document.createElement("a");
        anchor.href = fileURL;
        anchor.download = `${this.getProject.title} - ${design.title}.zip`;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(fileURL);
      } catch (error) {
        if (error.response.status === this.$config.http.NOT_FOUND) {
          this.$toast.open({
            message: "There are no files in Approved status",
            type: "error",
            position: "top-right",
          });
        } else {
          this.$toast.open({
            message: "Oops! Something went wrong!",
            type: "error",
            position: "top-right",
          });
        }
      } finally {
        design.fileDownloading = false;
      }
    },
    requestReview(data) {
      const designs = this.getProjectDesigns.map((design) =>
        design.items.length && data.files.includes(design.items[0].id)
          ? {
              ...design,
              loading: true,
            }
          : design
      );
      this.$store.dispatch("setProjectDesigns", designs);

      this.$api.project
        .requestReview(this.$route.params.project_id, data)
        .then((res) => {
          const request_reviews = res.data;
          const designs = this.getProjectDesigns.map((design) => {
            if (
              design.items.length &&
              data.files.includes(design.items[0].id)
            ) {
              return {
                ...design,
                loading: false,
                checked: false,
                items: [
                  {
                    ...design.items[0],
                    status: this.$config.project.status.in_review,
                    request_reviews: [
                      ...design.items[0].request_reviews,
                      ...request_reviews,
                    ],
                  },
                ],
              };
            }
            return { ...design, checked: false };
          });
          this.$store.dispatch("setProjectDesigns", designs);
          this.$toast.open({
            message: "Review requested",
            type: "success",
            position: "top-right",
          });
        })
        .catch((err) => {
          const designs = this.getProjectDesigns.map((design) =>
            design.loading ? { ...design, loading: false } : design
          );
          this.$store.dispatch("setProjectDesigns", designs);
          console.error(err);
        });
    },
    async changeActiveDesignModule(module_id) {
      this.$store.dispatch("setProjectDesignsLoading", true);

      await this.$store.dispatch("setProjectDesignsFilters", {
        module: module_id,
      });

      this.$router.$updateQueryParams({ module: module_id });

      const activeModule = this.getDesignModules.find(
        (module) => module.id === module_id
      );
      this.$store.dispatch("setActiveDesignModule", activeModule);

      this.$api.projectModuleDesign
        .list(
          this.$route.params.project_id,
          this.getProjectDesignsFilters.module,
          {
            ...this.getProjectDesignsFilters,
            sort_by: `${this.sort_1.slug}|${this.sort_2.slug}`,
          }
        )
        .then((res) => {
          this.$store.dispatch("setProjectDesigns", res.data);
        })
        .finally(() => {
          this.$store.dispatch("setProjectDesignsLoading", false);
        });
    },
    async setFilter(filterData) {
      if (filterData) {
        this.filterData[0].activeFilters = filterData[0].activeFilters;
        await this.$store.dispatch("setProjectDesignsFilters", {
          statuses: filterData[0].activeFilters,
        });
      }

      try {
        this.$store.dispatch("setProjectDesignsLoading", true);

        const res = await this.$api.projectModuleDesign.list(
          this.$route.params.project_id,
          this.getProjectDesignsFilters.module,
          {
            ...this.getProjectDesignsFilters,
            sort_by: `${this.sort_1.slug}|${this.sort_2.slug}`,
          }
        );

        await this.$store.dispatch("setProjectDesigns", res.data);
        this.$store.state.ProjectDesign.noCreatedStyles =
          this.getProjectDesigns.length === 0;
        this.$router.$updateQueryParams(this.getProjectDesignsFilters);
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.dispatch("setProjectDesignsLoading", false);
      }
    },
    async searchDesign(searchVal) {
      await this.$store.dispatch("setProjectDesignsFilters", {
        search: searchVal,
      });
      this.setFilter();
    },
    async setSort(key, value) {
      this[key] = value;

      this.setFilter();
    },
    async openTaskModal() {
      if (!this.$route.query.modal_task_id) return;

      try {
        const res = await this.$api.task.get(
          this.$route.params.project_id,
          this.$route.query.modal_task_id
        );
        let taskData = {
          ...res.data,
          due_date: new Date(res.data.due_date).getTime(),
          reminder: new Date(res.data.reminder).getTime(),
          loading: false,
          parent: { id: res.data.parent ? res.data.parent.id : null },
          children: res.data.children.map((child) => ({
            ...child,
            loading: false,
            parent: { id: res.data.parent ? res.data.parent.id : null },
            due_date: new Date(child.due_date).getTime(),
            reminder: new Date(child.reminder).getTime(),
          })),
        };

        await this.$store.dispatch("openModal", {
          modalName: "createNewTaskModal",
          data: {
            type: "edit",
            taskData,
          },
          handlers: {
            onUpdate: (task) => {
              if (this.getProjectSideDrawerTasks.data.length) {
                this.$store.dispatch("setProjectSideDrawerTabData", {
                  tab: "tasks",
                  data: this.getProjectSideDrawerTasks.data.map((sdTask) => {
                    if (task.parent && task.parent.id && sdTask.id) {
                      return {
                        ...sdTask,
                        children: sdTask.children.map((subTask) =>
                          subTask.id === task.id ? task : subTask
                        ),
                      };
                    } else if (sdTask.id === task.id) {
                      return task;
                    } else {
                      return sdTask;
                    }
                  }),
                });
              }
            },
            onClose: () => {
              this.$router.$updateQueryParams({ modal_task_id: null });
            },
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async onCompleteProject() {
      await this.completeProject([this.getProject.id], async () => {
        this.loading = true;
        await this.setupProject();
        this.loading = false;
      });
    },
    async setupProject() {
      const res = await this.$api.project.get(this.$route.params.project_id);
      await this.$store.dispatch("setProject", res.data);

      await this.getDesignsData();
    },
    goToEditFlow() {
      this.$router.push({
        name: "ProjectEditWorkflow",
        params: { project_id: this.$route.params.project_id },
      });
    },
    async getPermissions() {
      try {
        const permissions = await this.$api.project.permissions(
          this.getActiveSpace?.id
        );
        await this.$store.dispatch("setPermissions", permissions.data);
      } catch (err) {
        console.error(err);
      }
    },
    async updateProjectDueDate(val) {
      await this.$store.dispatch("updateProject", {
        ...this.getProject,
        due_date: val.due_date,
      });
    },
    async updateAllData() {
      this.loading = true;
      await this.setupProject();
      this.loading = false;
    },
    async getDesignsData() {
      this.tags = [...this.getProject.tag];
      const designModules = this.getProject.modules.filter(
        (module) => module.type === this.$config.project.moduleTypes.design
      );

      designModules.sort((a, b) => {
        if (a.workflow_type !== b.workflow_type) {
          return a.workflow_type - b.workflow_type;
        }
        return a.sort_order - b.sort_order;
      });

      const tagsStorage = await this.getProjectsTagsList();

      const {
        tags: routeTags,
        module,
        statuses,
        search = "",
        tagsList: routeTagsList,
        sort_1,
        sort_2,
      } = this.$route.query;

      const { tags: latestTags, tagsList: latestTagsList } =
        tagsStorage?.[this.$route.params.project_id] || {};

      // const tags = latestTags || routeTags // set latest user tag by default
      const tags = routeTags;
      const tagsList = latestTagsList || routeTagsList;

      const filters = {
        tags: !tags
          ? this.tags[0] && !tagsStorage?.[this.$route.params.project_id]
            ? [this.tags[0].id]
            : []
          : tags.split(",").reduce((acc, item) => {
              if (this.tags.find((i) => i.id === +item)) {
                acc.push(+item);
              }
              return acc;
            }, []),
        module: !module ? designModules[0].id : +module,
        statuses: !statuses ? [] : statuses.split(",").map((item) => +item),
        tagsList: !tagsList
          ? !tagsStorage?.[this.$route.params.project_id]
            ? this.tags.map((tag) => tag.id)
            : []
          : tagsList.split(",").reduce((acc, item) => {
              if (this.tags.find((i) => i.id === +item)) {
                acc.push(+item);
              }
              return acc;
            }, []),
        search,
      };

      if (sort_1 !== "" && sort_1 !== undefined) {
        this.sort_1 = this.sortList1.find((sort) => sort.slug === sort_1);
      }

      if (sort_2 !== "" && sort_2 !== undefined) {
        this.sort_2 = this.sortList2.find((sort) => sort.slug === sort_2);
      }

      await this.$store.dispatch("setProjectDesignsFilters", filters);
      await this.$router.$updateQueryParams(filters);
      this.initFiltersData();

      const activeDesignModule = designModules.find(
        (module) => module.id === filters.module
      );
      await this.$store.dispatch("setActiveDesignModule", activeDesignModule);
      await this.$store.dispatch("setDesignModules", designModules);

      const projectDesigns = await this.$api.projectModuleDesign.list(
        this.$route.params.project_id,
        filters.module,
        {
          ...filters,
          sort_by: `${this.sort_1.slug}|${this.sort_2.slug}`,
        }
      );

      this.$store.state.ProjectDesign.noCreatedStyles =
        designModules.length === 0;
      if (typeof projectDesigns === "string" && projectDesigns.length === 0) {
        await this.$store.dispatch("setProjectDesigns", []);
        this.$store.state.ProjectDesign.noCreatedStyles = true;
      } else {
        await this.$store.dispatch(
          "setProjectDesigns",
          projectDesigns?.data || []
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.design-search {
  width: 194px;
}
.unavailable-module {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
</style>
